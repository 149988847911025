import React, { FC, useCallback, useEffect } from 'react';
import { Modal } from '@/components/ui/Modal';
import { debounce } from '@/lib/helpers/debounce';
import { useBodyScrollLock } from '@/hooks/useBodyScrollLock';
import {
  CONSULTATION_FORM_STORAGE_KEY_BASE,
  CONSULTATION_FORM_STORAGE_VALUE,
} from '@/components/courseLanding/CourseConsultationForm/useFormStatusWithSession';
import { cn } from '@/lib';
import { removeQueryParams } from '@/controllers/analytics/analytics.utils/removeQueryParams';
import i18Next from '@/middleware/i18n';
import { emptyFunction } from '@/lib/helpers/functional';
import {
  getPayOutPopupStorageData,
} from '@/components/courseLanding/CourseBenefitsSection/CoursePayOutPopup/hooks/usePayOutPopupStatusWithSession';
import {
  ExitPopupEventType,
  useExitPopupEventEmitter,
} from '@/components/common/ExitPopup/hooks/useExitPopupEventEmitter';
import constants from './ExitPopup.constants';
import styles from './ExitPopup.module.scss';

interface Props {
  renderTitle: () => JSX.Element;
  onOpen?: (action: string) => void;
  onClose?: () => void;
  renderCloseButton?: boolean;
  isOpened?: boolean;
  className?: string;
  paddingless?: boolean;
  scrollDisabled?: boolean;
}

const getItem = (key: string) => {
  let item = null;

  try {
    item = sessionStorage.getItem(key);
  } catch { /* empty */ }

  return item;
};

const setItem = (key: string, value: string) => {
  try {
    sessionStorage.setItem(key, value);
  } catch { /* empty */ }
};

export const ExitPopup: FC<Props> = (props) => {
  const {
    renderTitle,
    onOpen = emptyFunction,
    onClose = emptyFunction,
    children,
    renderCloseButton,
    isOpened: isPopupOpened = true,
    className,
    paddingless,
    scrollDisabled,
  } = props;

  const router = i18Next.useRouter();
  const pathname = removeQueryParams(router.asPath);

  const STORAGE_KEY = `has_seen_exit_popup_at:${pathname}`;
  const STORAGE_VALUE = 'true';

  const CONSULTATION_STORAGE_KEY = `${CONSULTATION_FORM_STORAGE_KEY_BASE}:${pathname}`;
  const CONSULTATION_STORAGE_VALUE = CONSULTATION_FORM_STORAGE_VALUE;
  const {
    key: PAY_OUT_POPUP_STORAGE_KEY,
    value: PAY_OUT_POPUP_STORAGE_VALUE,
  } = getPayOutPopupStorageData(pathname);

  const {
    active: isOpened,
    setActive: setIsOpened,
  } = useBodyScrollLock(false);

  const exitPopupEventEmitter = useExitPopupEventEmitter();

  // mouseleave event
  useEffect(() => {
    const leaveCallback = () => {
      if (
        isOpened
        || getItem(STORAGE_KEY) === STORAGE_VALUE
        || getItem(CONSULTATION_STORAGE_KEY) === CONSULTATION_STORAGE_VALUE
        || getItem(PAY_OUT_POPUP_STORAGE_KEY) === PAY_OUT_POPUP_STORAGE_VALUE
      ) {
        return;
      }

      setIsOpened(true);
      setItem(STORAGE_KEY, STORAGE_VALUE);
      onOpen('document_mouseleave');
    };

    let timeoutId: ReturnType<typeof setTimeout>;

    const restartPopupTimeout = () => {
      clearTimeout(timeoutId);
      document.removeEventListener('mouseleave', leaveCallback);

      timeoutId = setTimeout(() => {
        document.addEventListener('mouseleave', leaveCallback);
      }, constants.POPUP_DELAY);
    };

    restartPopupTimeout();

    exitPopupEventEmitter.listen(
      ExitPopupEventType.ResetExitPopupTimeout,
      restartPopupTimeout,
    );

    return () => {
      document.removeEventListener('mouseleave', leaveCallback);
      exitPopupEventEmitter.unsubscribe(
        ExitPopupEventType.ResetExitPopupTimeout,
        restartPopupTimeout,
      );
      clearTimeout(timeoutId);
    };
  }, [
    exitPopupEventEmitter,
    setIsOpened,
    STORAGE_KEY,
    CONSULTATION_STORAGE_KEY,
    STORAGE_VALUE,
    CONSULTATION_STORAGE_VALUE,
    PAY_OUT_POPUP_STORAGE_VALUE,
    PAY_OUT_POPUP_STORAGE_KEY,
    isOpened,
    onOpen,
  ]);

  // inactive timeout
  useEffect(() => {
    const EVENTS = ['click', 'scroll', 'mousemove', 'keypress'];

    const leaveCallback = () => {
      if (
        isOpened
        || getItem(STORAGE_KEY) === STORAGE_VALUE
        || getItem(CONSULTATION_STORAGE_KEY) === CONSULTATION_STORAGE_VALUE
        || getItem(PAY_OUT_POPUP_STORAGE_KEY) === PAY_OUT_POPUP_STORAGE_VALUE
      ) {
        return;
      }

      setIsOpened(true);
      setItem(STORAGE_KEY, STORAGE_VALUE);
      onOpen('inactive_timeout');
    };

    let timeout = setTimeout(leaveCallback, constants.INACTIVE_DELAY);

    const clear = debounce(
      () => {
        clearTimeout(timeout);
        timeout = setTimeout(leaveCallback, constants.INACTIVE_DELAY);
      },
      50,
    );

    EVENTS.forEach((event) => {
      document.addEventListener(event, clear);
    });

    exitPopupEventEmitter.listen(
      ExitPopupEventType.ResetExitPopupTimeout,
      clear,
    );

    return () => {
      EVENTS.forEach((event) => {
        document.removeEventListener(event, clear);
      });
      exitPopupEventEmitter.unsubscribe(
        ExitPopupEventType.ResetExitPopupTimeout,
        clear,
      );
      clearTimeout(timeout);
    };
  }, [
    exitPopupEventEmitter,
    setIsOpened,
    STORAGE_KEY,
    CONSULTATION_STORAGE_KEY,
    STORAGE_VALUE,
    CONSULTATION_STORAGE_VALUE,
    PAY_OUT_POPUP_STORAGE_VALUE,
    PAY_OUT_POPUP_STORAGE_KEY,
    isOpened,
    onOpen,
  ]);

  const closeModal = useCallback(() => {
    setIsOpened(false);
    onClose();
  }, [setIsOpened, onClose]);

  // close popup when secondary(cancel) button is clicked
  useEffect(() => {
    if (!isPopupOpened) {
      closeModal();
    }
  }, [closeModal, isPopupOpened]);

  return (
    <Modal
      isOpen={isOpened}
      className={cn(styles.popup, className)}
      shouldCloseOnEsc
      onRequestClose={closeModal}
      renderTitle={renderTitle}
      titleClassNames={styles.title}
      renderCloseButton={renderCloseButton}
      paddingless={paddingless}
      scrollDisabled={scrollDisabled}
    >
      {children}
    </Modal>
  );
};
