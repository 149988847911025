import { useCallback, useState } from 'react';
import i18next from '@/middleware/i18n';
import { removeQueryParams } from '@/controllers/analytics/analytics.utils/removeQueryParams';
import {
  ExitPopupEventType,
  useExitPopupEventEmitter,
} from '@/components/common/ExitPopup/hooks/useExitPopupEventEmitter';

const PAY_OUT_POPUP_STORAGE_KEY_BASE = 'has_opened_pay_out_popup_at';

export const PAY_OUT_POPUP_STORAGE_VALUE = 'true';

export const getPayOutPopupStorageData = (pathname: string): {
  key: string;
  value: string;
} => {
  const activationKey = `${PAY_OUT_POPUP_STORAGE_KEY_BASE}:${pathname}`;

  return {
    key: activationKey,
    value: PAY_OUT_POPUP_STORAGE_VALUE,
  };
};

export const usePayOutPopupStatusWithSession = () => {
  const [activatedKeys, setActivatedKeys] = useState<string[]>([]);
  const router = i18next.useRouter();
  const pathname = removeQueryParams(router.asPath);
  const exitPopupEventEmitter = useExitPopupEventEmitter();

  return useCallback((isPopupOpened: boolean) => {
    const {
      key: activationKey,
      value,
    } = getPayOutPopupStorageData(pathname);

    if (isPopupOpened) {
      if (activatedKeys.includes(activationKey) || !pathname) {
        return;
      }

      setActivatedKeys((prev) => [...prev, activationKey]);

      try {
        sessionStorage.setItem(activationKey, value);
      } catch { /* empty */ }
    } else {
      setActivatedKeys((prev) => prev.filter((key) => key !== activationKey));

      try {
        sessionStorage.removeItem(activationKey);
      } catch { /* empty */ }

      exitPopupEventEmitter.notify({
        type: ExitPopupEventType.ResetExitPopupTimeout,
      });
    }
  }, [
    exitPopupEventEmitter,
    pathname,
    activatedKeys,
  ]);
};
