import { PromoCodeStatus } from '@/controllers/graphql/generated';
import { useSubDomainContext } from '@/controllers/subDomain/subDomain.hooks/useSubDomainContext';
import { SubDomains } from '@/constants/subdomains';
import { PromoCodeLandingBaseFragment } from '@/controllers/promoCode/generated/promoCodeLandingBase.fragment.generated';

interface Options {
  courseSlug?: string;
  promoCode?: PromoCodeLandingBaseFragment;
}

export const useShouldShowMateAnniversaryPromoPl = (
  options: Options,
) => {
  const { promoCode } = options;
  const { subDomain } = useSubDomainContext();

  const isCorrectDomain = subDomain === SubDomains.pl;
  const isPromoCodeActive = promoCode?.status === PromoCodeStatus.Active;

  return (
    isCorrectDomain
    && isPromoCodeActive
  );
};
