import { FC } from 'react';
import {
  useShouldShowCustomerWeekPromo,
} from '@/components/landing/Promotions/CustomerWeekPromo/useShouldShowCustomerWeekPromo';
import { CourseType } from '@/controllers/graphql/generated';
import { CustomerWeekPromoBanner } from '@/components/landing/Promotions/CustomerWeekPromo/CustomerWeekPromoBanner';
import {
  useShouldShowFreeEnglishPromo,
} from '@/components/landing/Promotions/FreeEnglishPromo/useShouldShowFreeEnglishPromo';
import { FreeEnglishPromoBanner } from '@/components/landing/Promotions/FreeEnglishPromo/FreeEnglishPromoBanner';
import { FreeEnglishPromoCard } from '@/components/landing/Promotions/FreeEnglishPromo/FreeEnglishPromoCard';
import { LimitedPromoPLBanner } from '@/components/landing/Promotions/LimitedPromo/LimitedPromoPLBanner';
import {
  useShouldShowOnePlusOnePromo,
} from '@/components/landing/Promotions/OnePlusOnePromo/useShouldShowOnePlusOnePromo';
import { OnePlusOnePromoBanner } from '@/components/landing/Promotions/OnePlusOnePromo/OnePlusOnePromoBanner';
import { OnePlusOnePromoCard } from '@/components/landing/Promotions/OnePlusOnePromo/OnePlusOnePromoCard';
import { useShouldShowMonetaryPromo } from '@/components/landing/Promotions/MonetaryPromo/useShouldShowMonetaryPromo';
import { MonetaryPromoCard } from '@/components/landing/Promotions/MonetaryPromo/MonetaryPromoCard';
import { MonetaryPromoBanner } from '@/components/landing/Promotions/MonetaryPromo/MonetaryPromoBanner';
import { useShouldShowUnlimPromo } from '@/components/landing/Promotions/UnlimPromo/useShouldShowUnlimPromo';
import { UnlimPromoCard } from '@/components/landing/Promotions/UnlimPromo/UnlimPromoCard';
import { UnlimPromoBanner } from '@/components/landing/Promotions/UnlimPromo/UnlimPromoBanner';
import {
  useShouldShowQaBrPresalePromo,
} from '@/components/landing/Promotions/QaBrPresalePromo/useShouldShowQaBrPresalePromo';
import {
  useShouldShowLimitedPromoBRBanner,
} from '@/components/landing/Promotions/LimitedPromo/useShouldShowLimitedPromoBRBanner';
import { LimitedPromoBRBanner } from '@/components/landing/Promotions/LimitedPromo/LimitedPromoBRBanner';
import {
  useShouldShowLimitedPromoPLBanner,
} from '@/components/landing/Promotions/LimitedPromo/useShouldShowLimitedPromoPLBanner';
import { LandingPromoCodeSlug } from '@/components/landing/Promotions/promotions.typedefs';
import { usePromotionsPromoCodesInfo } from '@/components/landing/Promotions/hooks/usePromotionsPromoCodesInfo.hook';
import { useFeatures } from '@/controllers/features/features.hooks/useFeatures';
import {
  useShouldShowDataAnalyticsPresaleEndPromo,
} from '@/components/landing/Promotions/DataAnalyticsPresalePromo/useShouldShowDataAnalyticsPresaleEndPromo';
import {
  useShouldShowDataAnalyticsPresaleStartPromo,
} from '@/components/landing/Promotions/DataAnalyticsPresalePromo/useShouldShowDataAnalyticsPresaleStartPromo';
import {
  DataAnalyticsPresaleStartPromoBanner,
} from '@/components/landing/Promotions/DataAnalyticsPresalePromo/DataAnalyticsPresaleStartPromoBanner';
import {
  DataAnalyticsPresaleEndPromoBanner,
} from '@/components/landing/Promotions/DataAnalyticsPresalePromo/DataAnalyticsPresaleEndPromoBanner';
import {
  QaBrPresalePromoEndBanner,
  QaBrPresalePromoEndCard,
  QaBrPresalePromoStartBanner,
  QaBrPresalePromoStartCard,
} from '@/components/landing/Promotions/QaBrPresalePromo';
import { useShouldShowFlyerOnePromo } from '@/components/landing/Promotions/FlyerOnePromo/useShouldShowFlyerOnePromo';
import { CustomerWeekPromoCard } from '../CustomerWeekPromo/CustomerWeekPromoCard';
import {
  useShouldShowPythonPlPresaleStartPromo,
} from '../PythonPlPresalePromo/useShouldShowPythonPlPresaleStartPromo';
import {
  useShouldShowPythonPlPresaleEndPromo,
} from '../PythonPlPresalePromo/useShouldShowPythonPlPresaleEndPromo';
import { PythonPlPresaleEndPromoBanner } from '../PythonPlPresalePromo/PythonPlPresaleEndPromoBanner';
import { PythonPlPresaleStartPromoBanner } from '../PythonPlPresalePromo/PythonPlPresaleStartPromoBanner';
import { PythonPlPresaleStartPromoCard } from '../PythonPlPresalePromo/PythonPlPresaleStartPromoCard';
import { PythonPlPresaleEndPromoCard } from '../PythonPlPresalePromo/PythonPlPresaleEndPromoCard';
import { useShouldShowKnowledgeDayPromo } from '../KnowledgeDayPromo/useShouldShowKnowledgeDayPromo';
import { KnowledgeDayPromoBanner } from '../KnowledgeDayPromo/KnowlegdeDayPromoBanner';
import { FlyerOnePromoBanner } from '../FlyerOnePromo/FlyerOnePromoBanner';
import { useShouldShowSecretBonusPromo } from '../SecretBonusPromo/useShouldShowSecretBonusPromo';
import { SecretBonusPromoBanner } from '../SecretBonusPromo/SecretBonusPromoBanner';
import { useShouldShowMateAnniversaryPromo } from '../MateAnniversaryPromo/useShouldShowMateAnniversaryPromo';
import { MateAnniversaryPromoBanner } from '../MateAnniversaryPromo/MateAnniversaryPromoBanner';
import { useShouldShowMateAnniversaryPromoPl } from '../MateAnniversaryPromoPl/useShouldShowMateAnniversaryPromoPl';
import { MateAnniversaryPromoBannerPl } from '../MateAnniversaryPromoPl/MateAnniversaryPromoBannerPl';

interface Options {
  courseType: CourseType;
  courseSlug?: string;
}

type Result = {
  PromoCard: FC<{ courseSlug: string }> | null;
  PromoBanner: FC<{
    courseSlug?: string;
    shouldShowRedesigned?: boolean;
  }> | null;
};

interface UseActivePromo {
  (options: Options): Result;
}

export const useActivePromo: UseActivePromo = (options) => {
  const { courseType, courseSlug } = options;
  const features = useFeatures();
  const promoCodes = usePromotionsPromoCodesInfo();
  const isMergedLandingEnabled = features.isEnabled(features.MergedLanding);

  const shouldShowLimitedPromoPLBanner = useShouldShowLimitedPromoPLBanner({
    courseType,
    promoCode: promoCodes[LandingPromoCodeSlug.LIMITED_OFFER_PL],
  });

  const shouldShowLimitedPromoBRBanner = useShouldShowLimitedPromoBRBanner({
    courseType,
    promoCode: promoCodes[LandingPromoCodeSlug.LIMITED_OFFER_BR],
  });

  const shouldShowCustomerWeekPromo = useShouldShowCustomerWeekPromo({
    courseType,
    promoCode: promoCodes[LandingPromoCodeSlug.CUSTOMER_WEEK_PROMO],
  });

  const shouldShowFreeEnglishPromo = useShouldShowFreeEnglishPromo({
    courseType,
    promoCode: promoCodes[LandingPromoCodeSlug.FREE_ENGLISH_PROMO],
    isMergedLandingEnabled,
  });

  const shouldShowOnePlusOnePromo = useShouldShowOnePlusOnePromo({
    courseType,
    courseSlug,
    promoCode: promoCodes[LandingPromoCodeSlug.ONE_PLUS_ONE_PROMO],
    isMergedLandingEnabled,
  });

  const shouldShowMonetaryPromo = useShouldShowMonetaryPromo({
    courseType,
    promoCode: promoCodes[LandingPromoCodeSlug.MONETARY_PROMO_UA],
    isMergedLandingEnabled,
  });

  const shouldShowUnlimPromo = useShouldShowUnlimPromo({
    courseType,
    promoCode: promoCodes[LandingPromoCodeSlug.UNLIM_PROMO],
    isMergedLandingEnabled,
  });

  const shouldShowQaBrPresaleStartPromo = (
    useShouldShowQaBrPresalePromo({
      courseSlug,
      promoCode: promoCodes[
        LandingPromoCodeSlug.QA_BR_PRESALE_START
      ],
    })
  );

  const shouldShowQaBrPresaleEndPromo = (
    useShouldShowQaBrPresalePromo({
      courseSlug,
      promoCode: promoCodes[
        LandingPromoCodeSlug.QA_BR_PRESALE_END
      ],
    })
  );

  const shouldShowDataAnalyticsPresaleStartPromo = (
    useShouldShowDataAnalyticsPresaleStartPromo({
      courseSlug,
      promoCode: promoCodes[
        LandingPromoCodeSlug.DATA_ANALYTICS_PRESALE_START_UA
      ],
    })
  );

  const shouldShowDataAnalyticsPresaleEndPromo = (
    useShouldShowDataAnalyticsPresaleEndPromo({
      courseSlug,
      promoCode: promoCodes[
        LandingPromoCodeSlug.DATA_ANALYTICS_PRESALE_END_UA
      ],
    })
  );

  const shouldShowPythonPlPresaleStartPromo = (
    useShouldShowPythonPlPresaleStartPromo({
      courseSlug,
      promoCode: promoCodes[
        LandingPromoCodeSlug.PYTHON_PL_PRESALE_START
      ],
    })
  );

  const shouldShowPythonPlPresaleEndPromo = (
    useShouldShowPythonPlPresaleEndPromo({
      courseSlug,
      promoCode: promoCodes[
        LandingPromoCodeSlug.PYTHON_PL_PRESALE_END
      ],
    })
  );

  const shouldShowKnowledgeDayPromo = (
    useShouldShowKnowledgeDayPromo({
      promoCode: promoCodes[
        LandingPromoCodeSlug.KNOWLEDGE_DAY_PROMO
      ],
    })
  );

  const shouldShowFlyerOnePromo = (
    useShouldShowFlyerOnePromo({
      courseSlug,
      promoCode: promoCodes[
        LandingPromoCodeSlug.FLYER_ONE_PROMO
      ],
    })
  );

  const shouldShowSecretBonusPromo = (
    useShouldShowSecretBonusPromo({
      promoCode: promoCodes[
        LandingPromoCodeSlug.SECRET_BONUS_PROMO
      ],
    })
  );

  const shouldShowMateAnniversaryPromo = (
    useShouldShowMateAnniversaryPromo({
      promoCode: promoCodes[
        LandingPromoCodeSlug.MATES_ANNIVERSARY_PROMO
      ],
    })
  );

  const shouldShowMateAnniversaryPromoPl = (
    useShouldShowMateAnniversaryPromoPl({
      promoCode: promoCodes[
        LandingPromoCodeSlug.MATES_ANNIVERSARY_PROMO_PL
      ],
    })
  );

  const shouldNotShowPromos = (
    features.isDisabled(features.LandingPromoBanners)
  );

  // ----- COMPONENTS -----
  if (shouldNotShowPromos) {
    return {
      PromoCard: null,
      PromoBanner: null,
    };
  }

  if (shouldShowFlyerOnePromo) {
    return {
      PromoCard: null,
      PromoBanner: FlyerOnePromoBanner,
    };
  }

  if (shouldShowDataAnalyticsPresaleEndPromo) {
    return {
      PromoCard: null,
      PromoBanner: DataAnalyticsPresaleEndPromoBanner,
    };
  }

  if (shouldShowDataAnalyticsPresaleStartPromo) {
    return {
      PromoCard: null,
      PromoBanner: DataAnalyticsPresaleStartPromoBanner,
    };
  }

  if (shouldShowQaBrPresaleStartPromo) {
    return {
      PromoCard: QaBrPresalePromoStartCard,
      PromoBanner: QaBrPresalePromoStartBanner,
    };
  }

  if (shouldShowQaBrPresaleEndPromo) {
    return {
      PromoCard: QaBrPresalePromoEndCard,
      PromoBanner: QaBrPresalePromoEndBanner,
    };
  }

  if (shouldShowPythonPlPresaleStartPromo) {
    return {
      PromoCard: PythonPlPresaleStartPromoCard,
      PromoBanner: PythonPlPresaleStartPromoBanner,
    };
  }

  if (shouldShowPythonPlPresaleEndPromo) {
    return {
      PromoCard: PythonPlPresaleEndPromoCard,
      PromoBanner: PythonPlPresaleEndPromoBanner,
    };
  }

  if (shouldShowUnlimPromo) {
    return {
      PromoCard: UnlimPromoCard,
      PromoBanner: UnlimPromoBanner,
    };
  }

  if (shouldShowMonetaryPromo) {
    return {
      PromoCard: MonetaryPromoCard,
      PromoBanner: MonetaryPromoBanner,
    };
  }

  if (shouldShowOnePlusOnePromo) {
    return {
      PromoCard: OnePlusOnePromoCard,
      PromoBanner: OnePlusOnePromoBanner,
    };
  }

  if (shouldShowFreeEnglishPromo) {
    return {
      PromoCard: FreeEnglishPromoCard,
      PromoBanner: FreeEnglishPromoBanner,
    };
  }

  if (shouldShowCustomerWeekPromo) {
    return {
      PromoCard: CustomerWeekPromoCard,
      PromoBanner: CustomerWeekPromoBanner,
    };
  }

  if (shouldShowLimitedPromoPLBanner) {
    return {
      PromoCard: null,
      PromoBanner: LimitedPromoPLBanner,
    };
  }

  if (shouldShowLimitedPromoBRBanner) {
    return {
      PromoCard: null,
      PromoBanner: LimitedPromoBRBanner,
    };
  }

  if (shouldShowKnowledgeDayPromo) {
    return {
      PromoCard: null,
      PromoBanner: KnowledgeDayPromoBanner,
    };
  }

  if (shouldShowSecretBonusPromo) {
    return {
      PromoCard: null,
      PromoBanner: SecretBonusPromoBanner,
    };
  }

  if (shouldShowMateAnniversaryPromo) {
    return {
      PromoCard: null,
      PromoBanner: MateAnniversaryPromoBanner,
    };
  }

  if (shouldShowMateAnniversaryPromoPl) {
    return {
      PromoCard: null,
      PromoBanner: MateAnniversaryPromoBannerPl,
    };
  }

  return {
    PromoCard: null,
    PromoBanner: null,
  };
};
